
import { computed, defineComponent, onMounted, Ref, ref } from '@vue/runtime-core'
import { DefaultTableExposed, ModalForm, NumberInput, FormErrors } from 'apptimizm-ui'
import axios from '@/axios'
import { ProjectMeta, ISelectValue, RmmSettingsMeta, RmmSettings } from '../projects/types'
import { EmployeeMeta, FormFields, FormFieldsMeta } from './types'
import Checkbox from '@/UI/checkbox'
import { getCeleryTask } from '../../core/celery'
import Table from './table.vue'
import { store, mutations } from '@/store/index'
import { ButtonLoader } from '../../UI/loader'
import { watch } from 'vue'

export default defineComponent({
  setup () {
    const employeeMeta = new EmployeeMeta()
    const table = ref(null) as unknown as Ref<DefaultTableExposed>
    const modalFormErrors: Ref<FormErrors> = ref(new FormErrors({}))
    const modalPopup = ref({ show: false, type: '' })
    const toggle = (type: string = '') => {
      modalPopup.value.show = !modalPopup.value.show
      modalPopup.value.type = type
      modalFormFields.value.reset()
    }
    const isLoading = ref<{ [code: string]: boolean }>({
      loadFile: false
    })

    // Получение информации о возможности устанавливать автоназначение
    const projectSelectedId = computed(() => store.currentProject.id)

    const projectMeta = new ProjectMeta()

    const rmmSettingsMeta = new RmmSettingsMeta()

    const rmmSettings = ref(new RmmSettings())

    const isAutoAssignment = computed(() => {
      return rmmSettings.value.autoZonesAmount > 0
    })

    async function getRmmSettings () {
      if (!projectSelectedId.value) return

      const project = await projectMeta.getProject(projectSelectedId.value)
      const rmmSettingsId = project.rmmSettings
      if (!rmmSettingsId) return

      rmmSettings.value = await rmmSettingsMeta.getRmmSettings(rmmSettingsId)
    }

    onMounted(async () => {
      await getRmmSettings()
    })

    watch(projectSelectedId, async () => {
      await getRmmSettings()
    })
    //

    const roleChoices: Ref<ISelectValue[]> = ref([])

    const tableFilter = {
      get tableParams () {
        const res: { [code: string]: string } = {}
        if (store.currentProject.id) res.project = store.currentProject.id

        return res
      }
    }

    const modalFormFields = ref(new FormFields())
    const modalFormFieldsMeta = new FormFieldsMeta()

    const isProjectSelected = computed(() => !!projectSelectedId.value)

    const addRoles = (value: string) => {
      if (!modalFormFields.value.roles.includes(value)) {
        modalFormFields.value.roles.push(value)
      } else {
        modalFormFields.value.roles.splice(modalFormFields.value.roles.indexOf(value), 1)
      }
    }

    const submit = async () => {
      modalFormFields.value.project = store.currentProject

      if (modalPopup.value.type === 'add') {
        const response = (await axios.post(modalFormFieldsMeta.endpointCreate, modalFormFieldsMeta.dump(modalFormFields.value))).data
        if (response?.errors) modalFormErrors.value = modalFormFieldsMeta.errors(response.errors)
        else {
          table.value.reload()
          toggle()
        }
      } else {
        const response = (await axios.post(modalFormFieldsMeta.endpointDelete, modalFormFieldsMeta.dump(modalFormFields.value))).data
        if (response?.errors) modalFormErrors.value = modalFormFieldsMeta.errors(response.errors)
        else {
          table.value.reload()
          toggle()
        }
      }
    }

    const loadFile = async () => {
      if (!store.currentProject.id) return

      isLoading.value.loadFile = true
      const response = (await axios.post(employeeMeta.endpoint + 'export_to_file/', { project: store.currentProject.id })).data
      const celaryResult = await getCeleryTask(response.result_id)
      if (celaryResult) {
        window.open(celaryResult.data.result)
        mutations.pushNotification(`Отчет по разделу Cотрудники в проекте ${store.currentProject.name} сформирован`)
      } else mutations.pushNotification('Ошибка формирования отчета', true)
      isLoading.value.loadFile = false
    }

    onMounted(async () => {
      if (!store.currentProject.id && localStorage.projectID) {
        try {
          const result = projectMeta.load((await axios.get(`${projectMeta.endpoint}${localStorage.projectID}`)).data)
          if (result?.id) mutations.setCurrentProject({ id: result.id, name: result.name, address: result.address })
        } catch (error) {
          mutations.pushNotification('Не удалось получить выбранный проект', true)
        }
      }
      roleChoices.value = (await axios.get(employeeMeta.endpoint + 'roles_choices/')).data
    })

    const modalForm = () => {
      return (
        <ModalForm cancel={toggle} confirm={toggle} header={modalPopup.value.type === 'add' ? 'Создание пользователей' : 'Удаление пользователей'}
          slots={{
            default: () => {
              return (
                <div class="mt-4">
                  {modalPopup.value.type === 'add'
                    ? <div>
                      <div class="row-50-50">
                        <label>
                          <NumberInput
                            placeholder="Начальный номер"
                            class={`${modalFormFields.value.startSerialNumber ? 'input-placeholder-up' : 'hide-zero'}`}
                            modelValue={modalFormFields.value.startSerialNumber}
                            onValueChange={(v: number) => { modalFormFields.value.startSerialNumber = parseInt(String(v)) } }
                            errors={modalFormErrors.value.forField('startSerialNumber')}
                          />
                        </label>
                        <label>
                          <NumberInput
                            placeholder="Количество"
                            class={`${modalFormFields.value.amount ? 'input-placeholder-up' : 'hide-zero'}`}
                            modelValue={modalFormFields.value.amount}
                            onValueChange={(v: number) => { modalFormFields.value.amount = parseInt(String(v)) } }
                            errors={modalFormErrors.value.forField('amount')}
                          />
                        </label>
                      </div>
                      <p class="mt-2">Выберете роли пользователей</p>
                      {roleChoices.value.map((item: ISelectValue) => {
                        return (
                          <div class="mt-2">
                            <Checkbox
                              onClick={() => addRoles(item.value)}
                              placeholder={item.name}
                              modelValue={modalFormFields.value.roles.includes(item.value)}
                            />
                          </div>
                        )
                      })}
                    </div>
                    : <div>
                      <div class="row-50-50">
                        <label>
                          <NumberInput
                            placeholder="Пользователи с..."
                            class={`${modalFormFields.value.startSerialNumber ? 'input-placeholder-up' : 'hide-zero'}`}
                            modelValue={modalFormFields.value.startSerialNumber}
                            onValueChange={(v: number) => { modalFormFields.value.startSerialNumber = parseInt(String(v)) } }
                            errors={modalFormErrors.value.forField('startSerialNumber')}
                          />
                        </label>
                        <label>
                          <NumberInput
                            placeholder="по..."
                            class={`${modalFormFields.value.endSerialNumber ? 'input-placeholder-up' : 'hide-zero'}`}
                            modelValue={modalFormFields.value.endSerialNumber}
                            onValueChange={(v: number) => { modalFormFields.value.endSerialNumber = parseInt(String(v)) }}
                            errors={modalFormErrors.value.forField('endSerialNumber')}
                          />
                        </label>
                      </div>
                    </div>
                  }
                </div>
              )
            },
            buttons: () => <button onClick={submit} class="primary-button mt-4">{modalPopup.value.type === 'add' ? 'Создать' : 'Удалить'}</button>
          }}
        />
      )
    }

    return () => (
      <div>
        <div class="page-top-panel">
          <h1>Cотрудники</h1>
          <div class="page-top-panel-row mt-5">
            <button disabled={!isProjectSelected.value} onClick={() => toggle('add')} class="button-add right-item">Добавить пользователей</button>
            <button disabled={!isProjectSelected.value} onClick={() => toggle('delete')} class="button-delete">Удалить пользователей</button>
          </div>
        </div>
        <div class="layout">
          {isProjectSelected.value ? <Table
            table={table}
            tableFilter={tableFilter}
            roles={roleChoices.value}
            isAutoAssignment={isAutoAssignment.value}
          /> : null}
        </div>
        {modalPopup.value.show ? modalForm() : null}
        <div class="bottom-row">
          <button
            disabled={!isProjectSelected.value} onClick={loadFile}
            class={!isLoading.value.loadFile ? 'button-save right-item' : 'button-save right-item load'}>
            <ButtonLoader hidden={!isLoading.value.loadFile}/>
            Сформировать отчёт
          </button>
        </div>
      </div>
    )
  }
})
